import request from '@/utils/request'

export const login = params => {
  return request({
    url: '/api/skNewMedia/media/index/login',
    method: 'post',
    data: params
  });
};

export const register = params => {
  return request({
    url: '/api/skNewMedia/media/index/register',
    method: 'post',
    data: params
  });
};

export const add = params => {
  return request({
    url: '/api/skNewMedia/media/information/submitInformation/save',
    method: 'post',
    data: params
  });
};

export const getQR = params => {
  return request({
    url: '/api/skNewMedia/media/wechat/member/getCode',
    method: 'get',
    params: params
  });
};

export const queryIsMember = params => {
  return request({
    url: '/api/skNewMedia/media/wechat/member/queryIsMember',
    method: 'get',
    params: params
  });
};

export const getUserInfo = params => {
  return request({
    url: '/api/skNewMedia/media/userAccount/getUserInfo?id='+params.accountId,
    method: 'post'
  });
};

