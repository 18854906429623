<template>
  <div class="top-main">
    <div class="top-main-title">
      <div class="top-main-title-content">
        <div>
          <span>欢迎访问嘉兴智行物联网技术有限公司网站</span>
          <span v-if="!token" @click="goNew('/login')" class="span-hover">登录</span>
          <span v-if="!token" @click="goNew('/register')" class="span-hover">注册</span>
          <span v-if="!isVip && token" @click="goNew('/recharge')" class="span-hover">VIP充值</span>
        </div>
        <div>
          <span @click="goNew('/')" class="span-hover">网站首页</span>
          <span v-if="token" >|</span>
          <span v-if="token"  @click="goNew('/me')" class="span-hover">用户中心</span>
          <!-- <span @click="test()">test</span> -->
        </div>
      </div>
    </div>

    <div class="top-main-search">
      <div class="search-logo">
        <img alt="logo" src="../../public/LOGO.svg">
        <!-- <span>闪开</span><span style="font-family: FZLanTingHeiS-R-GB;">·</span><span>新媒</span> -->
        <span>嘉兴智行物联网技术有限公司</span>
      </div>
      <!-- <el-input placeholder="请输入关键词查询" v-model="input" class="input-with-select search-content">
        <el-select v-model="select" slot="prepend" placeholder="请选择" style="width: 80px;">
          <el-option label="信息" value="1"></el-option>
        </el-select>
        <el-divider slot="prepend" direction="vertical"></el-divider>
        <el-button slot="append" icon="el-icon-search"></el-button>
      </el-input> -->
      <el-button type="warning" style="position: absolute;right: 0;" icon="el-icon-position"
        @click="handelPublish()">发布信息</el-button>
    </div>

    <div class="top-main-menu">
      <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect"
        background-color="#83B24C" text-color="#fff" active-text-color="#fff">
        <el-menu-item v-for="(item, index) in menuList" :key="index" :index="item.index">{{ item.name }}</el-menu-item>
      </el-menu>
    </div>

    <div class="top-main-breadcrumb" v-if="activeIndex != 1">
      <i class="el-icon-location" style="color: #e6a23c;font-size: 16px;"></i>
      <span>当前位置：</span>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item v-if="activeIndex < 5">首页</el-breadcrumb-item>
        <el-breadcrumb-item>{{ activeName1 }}</el-breadcrumb-item>
        <el-breadcrumb-item v-if="detail">{{ activeName2 }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <AddDialog :visible="dialogVisible" @close="closeDialog" @submit="submitDialog" @recharge="rechargeDialog">
    </AddDialog>

  </div>
</template>

<script>
import AddDialog from '../views/components/add.vue';
export default {
  name: 'MainHead',
  components: {
    AddDialog
  },
  data() {
    return {
      input: '',
      select: '1',
      activeIndex: '1',
      menuList: [
        { index: '1', name: '首页' },
        { index: '2', name: '充电内广告' },
        { index: '3', name: '推文消息广告' },
        { index: '4', name: '渠道跳转广告' },
      ],
      activeName1: '',
      activeName2: '',
      detail: false,
      dialogVisible: false,
      token: '',
      isVip: false,
    }
  },
  //刷新时
  created() {
    let pathname = window.location.pathname;
    if (pathname == '/detail') {
      this.changeName2()
    } else {
      this.changeActive(pathname)
    }

  },
  mounted() {
    this.token = localStorage.getItem('token')
    let userId = localStorage.getItem('userId')
    if (!userId && this.token) {
      localStorage.removeItem('token')
      this.$confirm(
        '登录已过期，请先前往登录',
        "系统提示",
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: "warning",
          customClass: 'my-confirm'  //使提示文字颜色变红
        }
      )
        .then(() => {
          // 用户点击了确定按钮
          this.goNew('/login')
        })
        .catch(() => {
          // 用户点击了取消按钮
        })
    }
    else if (this.token && userId) {
      this.checkVip()
    }
  },
  watch: {
    //点返回等
    '$route'(to) {
      // 处理网址变化
      // console.log(`从 ${from.path} 到 ${to.path}`);
      if (to.path == '/detail') {
        this.changeName2()
      } else {
        this.detail = false
        this.activeName2 = ''
        this.changeActive(to.path)
      }
    }
  },
  methods: {
    test() {
      localStorage.setItem('token', '')
    },
    checkVip() {
      this.$api.queryIsMember({ accountId: localStorage.getItem('userId') }).then((res) => {
        if (res.message == "该用户非会员!") {
          this.isVip = false
          localStorage.setItem('isVip', false)
        } else if (res.message == "该用户是会员!") {
          this.isVip = true
          localStorage.setItem('isVip', true)
        }
      })
    },
    handelPublish() {
      const token = localStorage.getItem('token'); // 从本地存储中获取 token
      if (!token) {
        this.$confirm(
          '暂未登录，请先前往登录',
          "系统提示",
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: "warning",
            customClass: 'my-confirm'  //使提示文字颜色变红
          }
        )
          .then(() => {
            // 用户点击了确定按钮
            this.goNew('/login')
          })
          .catch(() => {
            // 用户点击了取消按钮
            console.log('取消')
          })
      } else {
        if (!this.isVip) {
          this.$confirm(
            '当前用户不是VIP用户，是否前往充值？',
            "系统提示",
            {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: "warning",
              customClass: 'my-confirm'  //使提示文字颜色变红
            }
          )
            .then(() => {
              // 用户点击了确定按钮
              this.goNew('/recharge')
            })
        } else {
          this.dialogVisible = true
        }

      }
    },
    closeDialog() {
      this.dialogVisible = false
    },
    submitDialog() {
      this.dialogVisible = false
    },
    rechargeDialog() {
      this.dialogVisible = false
      this.goNew('/recharge')
    },
    goNew(val) {
      if (this.$route.path !== val) {
        this.$router.push(val);
      }
    },
    changeActive(toPath) {
      console.log(343434, toPath)
      if (toPath == '/charge') {
        this.activeIndex = '2'
      } else if (toPath == '/tweet') {
        this.activeIndex = '3'
      } else if (toPath == '/channel') {
        this.activeIndex = '4'
      } else if (toPath == '/me') {
        this.activeIndex = '5'
        this.activeName1 = '用户中心'
      } else {
        this.activeIndex = '1'
      }
      if (this.activeIndex < 5) {
        this.activeName1 = this.activeIndex == '1' ? '' : this.menuList[this.activeIndex - 1].name
      }

    },
    changeName2() {
      this.detail = true
      let title = this.$route.query.title
      this.activeName2 = title + ' - 详情'
      if (title == '充电页弹窗广告' || title == '充电页banner横幅' || title == '充值页广告') {
        this.activeIndex = '2'
        this.activeName1 = '充电内广告'
      } else if (title == '福利中心广告' || title == '充电消息广告' || title == '微信推文广告') {
        this.activeIndex = '3'
        this.activeName1 = '推文消息广告'
      } else if (title == '微信跳转广告' || title == '小程序跳转广告' || title == '支付宝跳转广告') {
        this.activeIndex = '4'
        this.activeName1 = '渠道跳转广告'
      }
    },
    handleSelect(key) {
      this.activeIndex = key
      this.activeName2 = ''
      if (key != 1) {
        this.activeName1 = this.menuList[key - 1].name
      }
      if (key == 1) {
        this.$router.push('/');
      } else if (key == 2) {
        // 示例代码
        if (this.$route.path !== '/charge') {
          this.$router.push('/charge');
        }
      } else if (key == 3) {
        // 示例代码
        if (this.$route.path !== '/tweet') {
          this.$router.push('/tweet');
        }
      } else if (key == 4) {
        // 示例代码
        if (this.$route.path !== '/channel') {
          this.$router.push('/channel');
        }
      }
    }
  }
}
</script>

<style scoped>
.top-main {
  width: 100%;
}

.top-main-title {
  width: 100%;
  height: 35px;
  background: #FFFFFF;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.05);
  z-index: 9999;
}

.top-main-title-content {
  position: relative;
  width: 82%;
  height: 100%;
  margin: 0 auto;
  /* background-color: rgb(94, 213, 213); */
  font-size: 12px;
  color: #999999;
  font-family: HarmonyOS Sans SC;
}

.top-main-title-content div {
  position: absolute;
  height: 100%;
  line-height: 100%;
  right: 0;
  display: flex;
  align-items: center;
  /* 实现垂直居中对齐 */
}

.top-main-title-content div:first-child {
  left: 0;
}

.top-main-title-content div span {
  margin-right: 10px;
}

.top-main-title-content div:first-child span:first-child {
  color: #2C2C2C;
  margin-right: 30px;
}

.top-main-search {
  position: relative;
  width: 82%;
  height: 80px;
  margin: 0 auto;

  display: flex;
  align-items: center;
}

.search-logo {
  position: absolute;
  left: 0;
}

.search-logo img {
  margin-top: 10px;
  margin-right: -30px;
  vertical-align: middle;
  object-fit: none;
  object-position: center center;
  width: 118px;
  height: 68px;
}

.search-logo span {
  color: #2A2A2A;
  font-weight: 400;
  font-size: 24px;
  vertical-align: middle;
  font-family: YouYuan;
  margin-right: 5px;
}

.search-content {
  width: 50%;
  margin: 0 auto 0 28%;
}

.top-main-menu {
  width: 100%;
  background-color: #83B24C;
  height: 45px;
}

.top-main-menu .el-menu {
  position: relative;
  width: 80%;
  margin: 0 auto;
  height: 45px;
}

.top-main-breadcrumb {
  width: 80%;
  margin: 0 auto;
  height: 55px;
  font-family: HarmonyOS Sans SC;
  font-size: 12px;
  color: #2A2A2A;
  line-height: 55px;
  display: flex;
  align-items: center;
  /* 垂直居中对齐 */
}

.top-main-breadcrumb .el-breadcrumb {
  display: inline-block;
  font-family: HarmonyOS Sans SC;
  font-size: 12px;
}
</style>

<style>
.input-with-select .el-input-group__prepend,
.input-with-select .el-input__inner,
.input-with-select .el-input-group__append {
  border: none;
  background-color: white;
  /* height: 80%; */
}

.input-with-select .el-input-group__prepend .el-select .el-input .el-input__suffix {
  display: none;
}

.top-main-menu .el-menu .is-active {
  background-color: #6F9B3C !important;
  border: none !important;
}

.top-main-menu .el-menu .el-menu-item {
  height: 45px;
  line-height: 45px;
  border: none;
}

.top-main-menu .el-menu.el-menu--horizontal {
  border: none;
}

.span-hover {
  cursor: pointer;
}

.span-hover:hover {
  font-weight: 600;
  color: #6F9B3C;
}
</style>