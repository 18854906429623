import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/home.vue'),
  },
  {
    path: '/me',
    name: 'me',
    component: () => import('../views/me.vue'),
  },
  {
    path: '/charge',
    name: 'charge',
    component: () => import('../views/charge.vue')
  },
  {
    path: '/tweet',
    name: 'tweet',
    component: () => import('../views/tweet.vue')
  },
  {
    path: '/channel',
    name: 'channel',
    component: () => import('../views/channel.vue')
  },
  {
    path: '/detail',
    name: 'detail',
    component: () => import('../views/detail.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/register.vue')
  },
  {
    path: '/recharge',
    name: 'recharge',
    component: () => import('../views/recharge.vue')
  },
  {
    path: '/404',
    component: () => import('../views/404.vue')
  },
  {
    path: '*',
    redirect: '/404'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
