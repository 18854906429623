import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import * as api from "@/api/api";
import { MessageBox } from 'element-ui';

Vue.config.productionTip = false

// 挂载api调用方法省去每个页面的引用操作
Object.defineProperty(Vue.prototype, '$api', {
  value: api
})


// 注册全局前置守卫
router.beforeEach((to, from, next) => {
  console.log(1111, localStorage.getItem('payment'))
  if (from.path === '/recharge' && (localStorage.getItem('payment') === undefined || localStorage.getItem('payment') === null)) {
    MessageBox.confirm('订单未支付完成，是否离开？', '确认', {
      confirmButtonText: '是',
      cancelButtonText: '否',
      type: 'warning'
    }).then(() => {
      localStorage.removeItem('startTime')
      localStorage.removeItem('remainingTime')
      localStorage.removeItem('payment')
      next();
    }).catch(() => {
      next(false);
    });
  } else {
    next();
  }
});

Vue.use(ElementUI);

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
