<template>
  <div id="app">
    <div v-if="isLoading" v-loading="isLoading" class="loading">
      <!-- 自定义 Loading 样式 -->
      <div class="spinner"></div>
    </div>
    <div v-else>
      <MainHead v-if="hAndB"></MainHead>
      <router-view />
      <MainTail v-if="hAndB" class="tail-box"></MainTail>

      <div style="position: absolute;" v-if="hAndB">
        <div class="fixed-container">
          <div class="item" @click="handelPublish">
            <i class="el-icon-position item-icon"></i>
            <span>发布</span>
          </div>
          <div class="item" @click="scrollToTop">
            <i class="el-icon-top item-icon"></i>
            <span>返回顶部</span>
          </div>
        </div>
      </div>

    </div>
    <AddDialog :visible="dialogVisible" @close="closeDialog" @submit="submitDialog" @recharge="rechargeDialog">
    </AddDialog>
  </div>
</template>

<script>
import MainHead from './components/MainHead.vue';
import MainTail from './components/MainTail.vue';
import AddDialog from './views/components/add.vue';

export default {
  name: 'App',
  components: {
    MainHead, MainTail, AddDialog
  },
  data() {
    return {
      isLoading: true, // 控制 Loading 的显示与隐藏
      hAndB: true,//true显示导航栏等
      dialogVisible: false,
    };
  },
  mounted() {
    // 模拟加载数据
    setTimeout(() => {
      console.log(33,window.location)
      if(window.location.pathname == '/404'){
        this.hAndB = false
      }
      this.isLoading = false; // 隐藏 Loading
    }, 600);
  },
  methods: {
    goNew(val) {
      if (this.$route.path !== val) {
        this.$router.push(val);
      }
    },
    closeDialog() {
      this.dialogVisible = false
    },
    submitDialog() {
      this.dialogVisible = false
    },
    rechargeDialog() {
      this.dialogVisible = false
      this.goNew('/recharge')
    },
    handelPublish() {
      const token = localStorage.getItem('token'); // 从本地存储中获取 token
      if (!token) {
        this.$confirm(
          '暂未登录，请先前往登录',
          "系统提示",
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: "warning",
            customClass: 'my-confirm'  //使提示文字颜色变红
          }
        )
          .then(() => {
            // 用户点击了确定按钮
            this.goNew('/login')
          })
          .catch(() => {
            // 用户点击了取消按钮
          })
      } else {
        let isVip = localStorage.getItem('isVip')
        console.log(1111111, isVip)
        if (isVip === 'false') {
          this.$confirm(
            '当前用户不是VIP用户，是否前往充值？',
            "系统提示",
            {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: "warning",
              customClass: 'my-confirm'  //使提示文字颜色变红
            }
          )
            .then(() => {
              // 用户点击了确定按钮
              this.goNew('/recharge')
            })
        } else {
          this.dialogVisible = true
        }
      }
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  },
  watch: {
    //点返回等
    '$route'(to) {
      // 处理网址变化
      // console.log(`从 ${from.path} 到 ${to.path}`);
      if (to.path == '/login' || to.path == '/register' || to.path == '/recharge') {
        this.hAndB = false
      } else {
        this.hAndB = true
      }
    }
  },
}
</script>

<style scoped>
.tail-box {
  /* position: absolute;
  bottom: 0; */
}

.fixed-container {
  position: fixed;
  width: 53px;
  border-radius: 3px;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  background-color: #fff;
  padding: 10px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.05);

}

.item {
  width: 100%;
  display: flex;
  flex-direction: column;
  /* 垂直排列 */
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
  font-size: 12px;
  color: #ECB332;
  border-bottom: 2px solid #EEEEEE;
  padding: 5px 0;
}

.item:last-child {
  color: #999999;
  border-bottom: none
}

.item-icon {
  font-size: 18px;
  margin-bottom: 3px;
}

.loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>

<style>
body {
  margin: 0;
  padding: 0;
  background-color: #f7f7f7;
}

#app {
  position: relative;
  width: 100%;
  height: 100%;
  /* min-height: 100vh; */
  /* 使容器至少与视口高度一样高 */
}
</style>
