<template>
  <div class="tail-main">

    <div class="tail-main-title">
      <div v-for="(item, index) in menuList" :key="index">
        <span>{{ item.name }}</span>
        <el-divider direction="vertical" v-if="index != 4"></el-divider>
      </div>
    </div>

    <p>地址：浙江省嘉兴市南湖区智慧产业园9幢5楼 <span style="margin-left: 20px;">客服电话：400-667-9990</span></p>
    <p>版权所有 嘉兴智行物联网技术有限公司 Copyright 2017. All Rights Reserved</p>
    <p><a href="http://beian.miit.gov.cn/" target="_blank">浙ICP备15017943号-6 </a></p>
    
  </div>
</template>
    
<script>
export default {
  name: 'MainTail',
  data() {
    return {
      menuList: [
        { index: '1', name: '首页' },
        { index: '2', name: '充电内广告' },
        { index: '3', name: '推文消息广告' },
        { index: '4', name: '渠道跳转广告' },
      ],
    }
  },
}
</script>
  
<style scoped>
.tail-main {
  width: 100%;
}

.tail-main-title {
  width: 100%;
  height: 40px;
  background: #83b24c;
  text-align: center;
  line-height: 40px;
  font-family: HarmonyOS Sans SC;
  font-size: 14px;
  color: #FFFFFF;
}

.tail-main-title div {
  display: inline-block;
}

.tail-main p ,.tail-main p a {
  font-family: HarmonyOS Sans SC;
  font-size: 14px;
  color: #9E9E9E;
  text-align: center;
}

.tail-main p a:hover {
  color: #6F9B3C;
}
</style>
    