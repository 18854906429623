<template>
  <el-dialog title="信息收集" :visible.sync="visible" :close-on-click-modal="false" :close-on-press-escape="false"
    @close="handleClose" width="500px" v-loading="loading" element-loading-text="拼命加载中"
    element-loading-spinner="el-icon-loading">
    <el-form ref="form" :model="form" label-width="110px" :label-position="'right'">
      <el-form-item label="公司名称" prop="company" :rules="[
    { required: true, message: '请输入公司名称', trigger: 'blur' },
  ]" v-if="form.type == 1">
        <el-input v-model="form.company" size="small" placeholder="请输入公司名称"
          :style="'width:' + formLabelWidth"></el-input>
      </el-form-item>
      <el-form-item label="联系人" prop="contact" :rules="[
    { required: true, message: '请输入联系人名称', trigger: 'blur' },
  ]">
        <el-input v-model="form.contact" size="small" placeholder="请输入联系人名称"
          :style="'width:' + formLabelWidth"></el-input>
      </el-form-item>
      <el-form-item label="联系电话" prop="mobile" :rules="[
    { required: true, message: '请输入电话', trigger: 'blur' },
    { pattern: /((\d{11})|^((\d{7,8})|(\d{4}|\d{3})-(\d{7,8})|(\d{4}|\d{3})-(\d{7,8})-(\d{4}|\d{3}|\d{2}|\d{1})|(\d{7,8})-(\d{4}|\d{3}|\d{2}|\d{1}))$)/, message: '请输入正确的格式', trigger: 'blur' },
  ]">
        <el-input v-model="form.mobile" size="small" placeholder="请输入联系电话"
          :style="'width:' + formLabelWidth"></el-input>
      </el-form-item>
      <el-form-item label="发布类型" required>
        <el-radio-group v-model="form.type">
          <el-radio :label="0">个人</el-radio>
          <el-radio :label="1">企业</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item :label="form.type != 1 ? '身份证照片' : '营业执照'" required>
        <el-upload :headers="upload.headers" :action="upload.url" :show-file-list="false"
          :on-success="handleAvatarSuccess" :on-progress="handleProgress" :on-error="handleErro" accept="image/gif,image/jpeg,image/jpg,image/png" name="upload">
          <el-button size="small" type="primary" v-loading="loadingImg">点击上传</el-button>
          <span style="text-align: left;width: 100%;margin-left: 20px;" v-if="form.imgurl">{{imgName}}
            <i class="el-icon-circle-check" style="color: #83b24c;margin-left: 10px;"></i>
          </span>
        </el-upload>
        <span v-if="imgErro" style="font-size: 12px;color: red;">请上传附件</span>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="handleClose()">取 消</el-button>
      <el-button type="primary" @click="handleSubmit()">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'AddPage',
  props: {
    visible: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      form: {
        company: '',
        contact: '',
        mobile: '',
        operator: '',
        type: 0,
        imgurl: ""
      },
      upload: {
        headers: '',
        url: '/api/skNewMedia/common/upload'
      },
      formLabelWidth: '80%',
      loading: false,
      imgErro: false,
      imgName:'',
      loadingImg:false
    }
  },
  created() {
    const token = localStorage.getItem('token'); // 从本地存储中获取 token
    this.upload.headers = {
      'Authorization': "Bearer " + token,
    }
  },
  methods: {
    handleProgress(){
      this.loadingImg = true
    },
    handleErro(){
      this.loadingImg = false
    },
    handleAvatarSuccess(res, file) {
      console.log(666, res, file)
      this.imgErro = false
      this.form.imgurl = res;
      this.imgName = file.name
      this.loadingImg = false
      this.$forceUpdate();
    },
    handleClose() {
      // 关闭事件处理函数
      this.$refs.form.resetFields(); // 重置表单验证状态和输入内容
      this.$emit('close');
    },
    handleSubmit() {
      this.imgErro = this.form.imgurl ? false : true
      this.form.company = this.form.type == 1 ? this.form.company : ''
      this.$refs["form"].validate((valid) => {
        if (valid && !this.imgErro) {
          this.loading = true
          let q = JSON.parse(JSON.stringify(this.form))
          q.operator = localStorage.getItem('token')
          q.accountId = localStorage.getItem('userId')
          this.$api.add(q).then(() => {
            // 关闭事件处理函数
            this.$refs.form.resetFields(); // 重置表单验证状态和输入内容
            this.$emit('submit');
          }).catch(() => {
          }).finally(() => {
            this.loading = false
          })
        } else {
          return false;
        }
      });
    }
  }
}
</script>
<style scoped>
.el-icon-plus:before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>