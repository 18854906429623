var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-dialog',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"title":"信息收集","visible":_vm.visible,"close-on-click-modal":false,"close-on-press-escape":false,"width":"500px","element-loading-text":"拼命加载中","element-loading-spinner":"el-icon-loading"},on:{"update:visible":function($event){_vm.visible=$event},"close":_vm.handleClose}},[_c('el-form',{ref:"form",attrs:{"model":_vm.form,"label-width":"110px","label-position":'right'}},[(_vm.form.type == 1)?_c('el-form-item',{attrs:{"label":"公司名称","prop":"company","rules":[
  { required: true, message: '请输入公司名称', trigger: 'blur' },
]}},[_c('el-input',{style:('width:' + _vm.formLabelWidth),attrs:{"size":"small","placeholder":"请输入公司名称"},model:{value:(_vm.form.company),callback:function ($$v) {_vm.$set(_vm.form, "company", $$v)},expression:"form.company"}})],1):_vm._e(),_c('el-form-item',{attrs:{"label":"联系人","prop":"contact","rules":[
  { required: true, message: '请输入联系人名称', trigger: 'blur' },
]}},[_c('el-input',{style:('width:' + _vm.formLabelWidth),attrs:{"size":"small","placeholder":"请输入联系人名称"},model:{value:(_vm.form.contact),callback:function ($$v) {_vm.$set(_vm.form, "contact", $$v)},expression:"form.contact"}})],1),_c('el-form-item',{attrs:{"label":"联系电话","prop":"mobile","rules":[
  { required: true, message: '请输入电话', trigger: 'blur' },
  { pattern: /((\d{11})|^((\d{7,8})|(\d{4}|\d{3})-(\d{7,8})|(\d{4}|\d{3})-(\d{7,8})-(\d{4}|\d{3}|\d{2}|\d{1})|(\d{7,8})-(\d{4}|\d{3}|\d{2}|\d{1}))$)/, message: '请输入正确的格式', trigger: 'blur' },
]}},[_c('el-input',{style:('width:' + _vm.formLabelWidth),attrs:{"size":"small","placeholder":"请输入联系电话"},model:{value:(_vm.form.mobile),callback:function ($$v) {_vm.$set(_vm.form, "mobile", $$v)},expression:"form.mobile"}})],1),_c('el-form-item',{attrs:{"label":"发布类型","required":""}},[_c('el-radio-group',{model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}},[_c('el-radio',{attrs:{"label":0}},[_vm._v("个人")]),_c('el-radio',{attrs:{"label":1}},[_vm._v("企业")])],1)],1),_c('el-form-item',{attrs:{"label":_vm.form.type != 1 ? '身份证照片' : '营业执照',"required":""}},[_c('el-upload',{attrs:{"headers":_vm.upload.headers,"action":_vm.upload.url,"show-file-list":false,"on-success":_vm.handleAvatarSuccess,"on-progress":_vm.handleProgress,"on-error":_vm.handleErro,"accept":"image/gif,image/jpeg,image/jpg,image/png","name":"upload"}},[_c('el-button',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loadingImg),expression:"loadingImg"}],attrs:{"size":"small","type":"primary"}},[_vm._v("点击上传")]),(_vm.form.imgurl)?_c('span',{staticStyle:{"text-align":"left","width":"100%","margin-left":"20px"}},[_vm._v(_vm._s(_vm.imgName)+" "),_c('i',{staticClass:"el-icon-circle-check",staticStyle:{"color":"#83b24c","margin-left":"10px"}})]):_vm._e()],1),(_vm.imgErro)?_c('span',{staticStyle:{"font-size":"12px","color":"red"}},[_vm._v("请上传附件")]):_vm._e()],1)],1),_c('div',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){return _vm.handleClose()}}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.handleSubmit()}}},[_vm._v("确 定")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }